.contactSection {
    background-color: var(--primary-bg-color);
    min-height: 100vh;
}

.contactSectionTitle {
    padding-top: 2.5rem;
}


.contactSectionTitle h2 {
    color: var(--light-font-color);
    font-size: clamp(2.75rem, 4.25vw, 6vw);
    text-align: center;
}

.contactOptionContainer {
    display: grid;
    grid-template-columns: 65% 25%;
    gap: 10%;
    margin-top: 2.5rem;
    align-items: center;
    min-height: 90vh
}

.emailMessengerWhatsAppContainer {

    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.contactOptionsBox {
    align-items: center;
    border: 2px solid var(--light-font-color);
    border-radius: 20px;
    color: var(--light-font-color);
    height: 10rem;
    height: clamp(9rem, 10rem, 16vw);
    gap: .5rem;
    padding: .25rem 0 .25rem 0;
    width: clamp(15rem, 17rem, 20vw);

    justify-content: center;

    display: flex;
    flex-direction: column;

    box-shadow: var(--secondary-shadow);

}



.contactIcons {

    height: clamp(1rem, 1.75rem, 6vw);
    margin-bottom: -.5rem;
    width: clamp(1rem, 1.75rem, 6vw);

}

.contactOptionsBox h3 {
    font-size: clamp(1rem, 1.20rem, 3vw);
}


.contactOptionsBox h4 {
    font-family: 'Poppins';
    font-size: clamp(.85rem, 1rem, 3vw);
    font-weight: 400;
}

.contactOptionsBox a {
    color: var(--highlighter-color);
    font-family: 'Poppins';
    font-size: clamp(.85rem, 1rem, 3vw);
    font-weight: 400;
    margin-bottom: .25rem;
}

/* =============== MEDIA QUERIES (MEDIUM DEVICES) =============== */
@media screen and (max-width: 1024px) {}

/* =============== MEDIA QUERIES (SMALL DEVICES) =============== */
@media screen and (max-width: 600px) {

    .contactOptionContainer {
        align-items: center;
        grid-template-columns: 100%;
        justify-content: center;

        padding-bottom: 5rem;


    }

    .emailMessengerWhatsAppContainer {
        align-items: center;
    }

    .emailMessengerWhatsAppContainer {
        height: 25vh;
        margin-top: -2rem;
        overflow-y: scroll;
        margin-bottom: 3rem;
    }


}