@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Bodoni+Moda:ital,opsz,wght@0,6..96,400..900;1,6..96,400..900&display=swap');
;

/* .bodoni-moda-<uniquifier> {
  font-family: "Bodoni Moda", serif;
  font-optical-sizing: auto;
  font-weight: <weight>;
  font-style: normal;
} */

body {
  background-color: var(--primary-bg-color);
  margin: 0;
  font-family: "Abril Fatface", serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Abril Fatface", serif;
  font-weight: 400;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

:root {
  --primary-bg-color: #2D2D2D;
  --light-font-color: #FFFFFF;
  --dark-font-color: #000000;
  --highlighter-color: #fef100;


  --container-width-lg: 85%;
  --container-width-md: 90%;
  --container-width-sm: 95%;

  --transition-in: all 500ms ease-in;
  --transition-out: all 500ms ease-out;
  --transition-duration: 250ms, 250ms, 250ms, 250ms;

  --primary-text-shadow: 1px 0px 2px rgba(0, 0, 0, 1);
  --light-text-shadow: .5px 0px 1px rgba(0, 0, 0, .25);

  --primary-box-shadow: 2px 0px 5px rgba(0, 0, 0, 1);
  --secondary-shadow: .35rem .25rem .10rem rgba(0, 0, 0, 0.336);
}

html {

  scroll-behavior: smooth;
}

.sectionTitle {
  padding-top: 2.5rem;
}

.sectionTitle h2 {
  color: var(--light-font-color);
  font-size: clamp(2rem, 4.25vw, 6vw);
  text-shadow: var(--primary-box-shadow);
  text-align: center;
}


.container {
  width: var(--container-width-lg);
  margin: 0 auto;
}

/* =============== MEDIA QUERIES (MEDIUM DEVICES) =============== */
@media screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-md);
  }

}

/* =============== MEDIA QUERIES (SMALL DEVICES) =============== */
@media screen and (max-width: 600px) {
  .container {
    width: var(--container-width-sm);
  }

}