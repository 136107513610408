.logoTop {
    color: var(--light-font-color);
    cursor: pointer;
    font-size: clamp(1.5rem, 3rem, 9vw);
    /* font-size: 3rem; */
    margin-bottom: -.75rem;


}

.logoBottom {
    color: var(--light-font-color);
    cursor: pointer;
    font-size: clamp(.85rem, 1.15rem, 4.75vw);
    letter-spacing: clamp(.16rem, .98rem, 2.25vw);
    font-weight: 100;


}

/* =============== MEDIA QUERIES (MEDIUM DEVICES) =============== */
@media screen and (max-width: 1024px) {}

/* =============== MEDIA QUERIES (SMALL DEVICES) =============== */
@media screen and (max-width: 600px) {
    .logoBottom {
        margin-top: .25rem;
    }
}