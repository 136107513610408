.aboutSection {
    background-color: var(--primary-bg-color);
    height: 105vh;
    margin-bottom: 3rem;
}

.aboutTitleContainer {
    padding-top: 2.5rem;
}

.aboutTitleContainer h2 {
    color: var(--light-font-color);
    font-size: clamp(2.75rem, 4.25vw, 6vw);
    text-align: center;
}


.ownerSection {
    display: grid;
    grid-template-columns: 30% 65%;
    gap: 5%;
    margin-top: 2.5rem;

}


.ownerTitleContainer h3 {
    color: var(--light-font-color);
    font-size: clamp(.85rem, 2.5rem, 4vw);
    margin-bottom: 1rem;
}

.ownerParagraphContainer p {
    color: var(--light-font-color);
    font-family: "Poppins";
    margin-bottom: 1rem;
    font-size: clamp(.85rem, 1.10rem, 4vw);
    text-align: justify;
}


/* =============== MEDIA QUERIES (MEDIUM DEVICES) =============== */
@media screen and (max-width: 1024px) {

    .ownerSectionColumn {
        overflow-y: scroll;
        padding-bottom: 2rem;
        height: 80vh;

    }
}

/* =============== MEDIA QUERIES (SMALL DEVICES) =============== */
@media screen and (max-width: 600px) {
    .aboutSection {
        padding-bottom: 2rem;
    }

    .aboutSectionContainer {
        margin-top: 12rem;

    }

    .ownerSection {
        display: flex;
        flex-direction: column;
    }

    .ownerSectionColumn {
        align-items: center;
        display: flex;
        flex-direction: column;
        margin-top: 2rem;
        overflow-y: scroll;
        padding-bottom: 2rem;
        height: 45vh;

        padding-bottom: 5rem;

    }
}