.indexBackground {
    min-height: 86vh;
    overflow: hidden;
    width: 100%;
}


#heroSection {
    width: 100%;
    height: 86vh;
}

.homePageContainer {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 86vh;
    margin-bottom: 5rem;

}

.titleContainer {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 20.75rem;
    justify-content: center;

}


.titleContainer .words span {
    animation: spin_words 45s ease infinite;
    color: var(--light-font-color);
    display: block;
    font-size: clamp(3.75rem, 12.5vw, 14.5vw);
    height: 17rem;
    text-align: center;
    text-shadow: var(--primary-text-shadow);



}

.words {
    overflow: hidden;
    margin-bottom: -1rem;
}


.titleContainer h2 {

    color: var(--light-font-color);
    font-size: clamp(1.25rem, 4rem, 6vw);
    font-weight: 400;
    text-shadow: var(--primary-text-shadow);
    text-align: center;

}

.contactButtonBar {
    margin-top: 2rem;
}

.heroContactButton {
    background-color: var(--highlighter-color);
    border: 2px solid var(--primary-bg-color);
    border-radius: 10px;
    cursor: pointer;
    font-family: "Abril Fatface", serif;
    font-size: clamp(.85rem, 1.5rem, 5vw);
    font-weight: 800;
    height: clamp(2.25rem, 3rem, 6vw);
    margin-left: 1rem;
    padding: 0.25rem;
    box-shadow: var(--primary-box-shadow);
    width: clamp(8rem, 12rem, 8vw);
}

.heroContactButton:active {

    transform: translateY(4px);
}

button.heroContactButton a {
    color: var(--primary-bg-color);
}




@keyframes spin_words {

    /* 0% {
        transform: translateY(0%)
    } */

    5% {
        transform: translateY(-100%)
    }

    10% {
        transform: translateY(-200%)
    }

    15% {
        transform: translateY(-300%)
    }

    20% {
        transform: translateY(-400%)
    }

    25% {
        transform: translateY(-500%)
    }

    30% {
        transform: translateY(-600%)
    }

    35% {
        transform: translateY(-700%)
    }

    40% {
        transform: translateY(-800%)
    }

    45% {
        transform: translateY(-900%)
    }

    50% {
        transform: translateY(-1000%)
    }

    55% {
        transform: translateY(-1100%)
    }

    60% {
        transform: translateY(-1200%)
    }

    65% {
        transform: translateY(-1300%)
    }

    70% {
        transform: translateY(-1400%)
    }

    75% {
        transform: translateY(-1500%)
    }

    80% {
        transform: translateY(-1600%)
    }

    85% {
        transform: translateY(-1700%)
    }

    90% {
        transform: translateY(-1800%)
    }

    95% {
        transform: translateY(-1900%)
    }

    100% {
        transform: translateY(-2000%)
    }


}



/* =============== MEDIA QUERIES (MEDIUM DEVICES) =============== */
@media screen and (max-width: 1024px) {


    .titleContainer {
        height: 12.5rem;
    }

}

/* =============== MEDIA QUERIES (SMALL DEVICES) =============== */
@media screen and (max-width: 600px) {

    .titleContainer {
        margin-top: -8em;
        height: 7.50rem;
    }

    .titleContainer .words span {

        height: 8rem;
    }

    .heroContactButton {

        padding-bottom: 2rem;
        font-weight: 800;
        margin-left: 1rem;

    }
}