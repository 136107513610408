.midSection {

    min-height: 30vh;
    width: 100%;
}




/* =============== MEDIA QUERIES (MEDIUM DEVICES) =============== */
@media screen and (max-width: 1024px) {
    #midSection {
        font-size: 2rem;
        height: 26vh;

    }

    #midSectionTwo {
        height: 26vh;
        margin-bottom: -2rem;
    }

}

/* =============== MEDIA QUERIES (SMALL DEVICES) =============== */
@media screen and (max-width: 600px) {

    #midSection {
        height: 1vh;

    }



}