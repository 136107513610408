.footerSection {
    background-color: var(--dark-font-color);
    height: auto;
}


.footerContainer {
    display: flex;
    padding: 2.5rem 0 2.5rem 0;
}

.topFooterContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
}



.footerLogoAddressContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;

}

.footerAddressContainer,
.footerPhoneNumberContainer {
    color: var(--light-font-color);

}

.footerAddressContainer h3,
.footerPhoneNumberContainer h3 {
    text-align: center;
    font-family: 'Poppins';
    font-size: clamp(.65rem, 1rem, 4vw);
    font-weight: 400;
}


.socialIconsContainer {
    cursor: pointer;
    display: flex;
    gap: 2rem;

}

.footerSocialIcons {
    color: var(--light-font-color);
    height: clamp(.95rem, 1.25rem, 5vw);
    width: clamp(.95rem, 1.25rem, 5vw);
}

.footerSiteLinksContainer ul {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: flex-start;
}

.footerSiteLinksContainer ul h3 {
    color: var(--light-font-color);
    font-size: clamp(1.25rem, 1.75vw, 2rem);
}

.footerSiteLinksContainer ul h3::after {
    background: var(--highlighter-color);
    content: '';
    position: absolute;
    top: 1.80rem;
    width: 0%;
    height: 2px;
    left: 50%;

    transform: translateX(-50%);
    transition: .3s
}

.footerSiteLinksContainer ul h3:hover {
    color: var(--highlighter-color);
}


.footerSiteLinksContainer ul h3:hover::after {
    width: 90%;
}


.footerNewsletterContainer {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 1rem;

}

.footerNewsletterContainer h3 {
    color: var(--light-font-color);
    font-size: clamp(.90rem, 1.20rem, 16vw);
}



.bottomFooterContainer {
    align-items: center;
    border-top: 1px solid white;
    display: flex;
    height: 2.5rem;
    justify-content: center;
    /* margin-top: 1rem; */
    width: 100%;

}


.rightsCopyrightContainer {
    color: var(--light-font-color);
}

.rightsCopyrightContainer h3 {
    font-size: clamp(.90rem, 1.2rem, 3vw);
}


/* =============== MEDIA QUERIES (MEDIUM DEVICES) =============== */
@media screen and (max-width: 1024px) {}

/* =============== MEDIA QUERIES (SMALL DEVICES) =============== */
@media screen and (max-width: 600px) {

    .topFooterContainer {
        flex-direction: column;
        gap: 2rem;
    }

    .footerSiteLinksContainer ul {
        flex-direction: row;
        justify-content: space-around;

    }

    .footerLogoAddressContainer {
        order: 1;
    }

    .footerNewsletterContainer {
        order: 3
    }
}