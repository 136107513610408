.aboutPhotoSection {
    align-items: center;
    display: flex;
    flex-direction: column;

}

.aboutPhotoContainer {
    aspect-ratio: 1/1;
    background: linear-gradient(45deg, transparent, #ffffff, transparent);
    border-radius: 2rem;
    display: grid;
    place-items: center;
    min-height: 78%;
    width: 83%;

}

.aboutImageContainer {
    border-radius: 2rem;
    overflow: hidden;
    transform: rotate(10deg);
    transition: var(--transition-in);

}

.aboutImageContainer:hover {
    transform: rotate(0deg);

}



.aboutPhotoSection figcaption {
    color: var(--light-font-color);
    margin-top: 1.5rem;
}

/* =============== MEDIA QUERIES (MEDIUM DEVICES) =============== */
@media screen and (max-width: 1024px) {

    .aboutPhotoContainer {
        width: 108%;

    }

    .aboutImageContainer {

        width: 15rem;
        aspect-ratio: 1/1;
    }

}

/* =============== MEDIA QUERIES (SMALL DEVICES) =============== */
@media screen and (max-width: 600px) {
    .aboutPhotoSection {
        display: flex;
        justify-content: center;
    }

    .aboutPhotoContainer {
        width: 12rem;
        height: 12rem;
    }

    .aboutImageContainer {
        width: 12rem;
        height: 12rem;

    }
}