.validationSection {
    min-height: 30vh;
}

.validationContainer {
    align-items: center;
    display: flex;
    min-height: 30vh;

    overflow: hidden;


}

.scroller {
    max-width: 100%;
}


.scroller__inner {
    padding-block: 2rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

}



.scroller[data-animated="true"] {
    overflow: hidden;
    -webkit-mask: linear-gradient(90deg, transparent, black 10%, black 90%, transparent);
    mask: linear-gradient(90deg, transparent, black 10%, black 90%, transparent);
}

.scroller[data-animated="true"] .scroller__inner {
    flex-wrap: nowrap;
    width: max-content;
    animation: scroll var(--_animation-duration, 50s) var(--_animation-direction, forwards) linear infinite;
}

.scroller[data-speed="slow"] .scroller__inner {
    --_animation-duration: 50s
}



.reviewBox {
    background-color: var(--primary-bg-color);
    border-radius: 10px;
    color: var(--light-font-color);
    font-family: 'Poppins';
    font-size: clamp(.75rem, .85rem, 6vw);
    height: clamp(11rem, 13rem, 40vw);
    width: clamp(20rem, 23rem, 40vw);

    padding: .75rem 1.5rem;

    display: flex;
    flex-direction: column;
    justify-content: space-around;


    box-shadow: var(--primary-box-shadow);

}

.reviewBox h3 {
    text-align: center;
    font-size: clamp(.95rem, 1.25rem, 6vw);

}


.reviewBox .custReview {
    color: var(--highlighter-color);
    text-align: justify;
}

.nameLocationContainer {
    display: flex;
    gap: .50rem;
    margin-top: .5rem;
}

.nameLocationContainer .custName {
    font-weight: 600;
    ;
}

@keyframes scroll {
    to {
        transform: translate(calc(-50% - 0.5rem))
    }
}