.emailFormSection form {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

}

.emailLabelContainer label {
    color: var(--highlighter-color);
    font-size: clamp(.85rem, 1.25rem, 6vw);
    font-weight: 900;

}


.formControlWrap input {
    border-right: 0;
    border-radius: 5px 0 0 5px;
    font-size: 1rem;
    height: 3rem;
    padding: 0.5rem;
    width: clamp(10rem, 20rem, 60vw);

}


.formControlWrap input:focus {
    outline: none;
}

button.emailButton {
    background-color: transparent;
    border-radius: 0 5px 5px 0;
    border: 2px solid var(--light-font-color);
    color: var(--light-font-color);
    cursor: pointer;
    display: inline-block;
    font-size: 1rem;
    height: 3rem;



    width: 5rem;
}


.successContainer {
    align-items: center;
    display: flex;
}

p.successMessage {
    color: var(--highlighter-color);
    margin-top: .5rem;
}

p.errorMessage {
    color: rgb(251, 116, 116);
    margin-top: .5rem;
}


.successContainer img {
    animation: rotate-record 4s infinite linear;
    height: 2rem;
    margin-top: 1rem;
    width: 2rem;

}

.recaptchaBox {
    margin-top: 1rem;

}





/* 
span.wpcf7-form-control-wrap.recaptcha {
    border: 3px solid red;
}

.rc-anchor-pt {
    border: 3px solid green;
}

#recaptcha-anchor-label {
    border: 2px solid yellow;
} */


/* * * * * * * * * * * * * FOOTER SIGN UP SECTION * * * * * * * * * * * * */



form.footerNewsForm {

    display: flex;
    flex-direction: column;
    align-items: flex-start;

}


.footerEmailLabelContainer {
    margin-bottom: -1.5rem;
}

.footerEmailLabelContainer label {
    color: var(--highlighter-color);
    font-size: clamp(.95rem, 1.15rem, 6vw);
    font-weight: 400;
    display: flex;
    justify-content: center;
    width: clamp(15rem, 21rem, 60vw);

}


input#footerEmail {
    border-right: 0;
    border-radius: 5px 0 0 5px;
    height: 3rem;
    font-size: 1rem;
    margin: 1rem 0 1rem 0;
    padding: 0.5rem;
    width: clamp(10rem, 18rem, 60vw);

}

input#footerEmail:focus {
    outline: none;
}

button.footerEmailButton {
    background-color: var(--highlighter-color);
    border: 2px solid var(--light-font-color);
    border-radius: 0 5px 5px 0;

    color: var(--dark-font-color);
    cursor: pointer;
    display: inline-block;
    font-size: 1rem;
    height: 3rem;
    width: 3rem;
}

button.footerEmailButton:active {
    transform: translateY(1px);
}


.footerSuccessContainer {
    align-items: center;
    display: flex;
}

p.footerSuccessMessage {
    color: var(--highlighter-color);
    margin-top: 1rem;
}

p.footerErrorMessage {
    color: rgb(251, 116, 116);
    margin-top: 1rem;
}


.footerSuccessContainer img {
    animation: rotate-record 4s infinite linear;
    height: 2rem;
    margin-top: 1rem;
    width: 2rem;

}

/* CLSOE OF FOOTER SIGN UP SECTION */

@keyframes rotate-record {
    from {
        tranform: rotate(0deg)
    }

    to {
        transform: rotate(360deg)
    }
}


/* =============== MEDIA QUERIES (MEDIUM DEVICES) =============== */
@media screen and (max-width: 1024px) {}

/* =============== MEDIA QUERIES (SMALL DEVICES) =============== */
@media screen and (max-width: 600px) {}